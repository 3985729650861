<template>
  <div class="user-management-container">
    <div class="user-management-box">
      <div class="header">
        <span class="title">用户管理</span>
        <div class="actions">
          <el-input placeholder="请输入" v-model="searchQuery" class="search-input">
            <template #prefix>
              <el-icon>
                <Search />
              </el-icon>
            </template>
          </el-input>
          <el-button type="primary" @click="syncUsers" class="custom-button1">同步用户</el-button>
          <el-button type="primary" @click="openDialog('add')" class="custom-button">添加用户</el-button>
        </div>
      </div>
      <div class="table-container">
        <el-table :data="paginatedData" class="custom-table" :header-cell-style="{ background: '#F5F6F7' }">
          <el-table-column prop="name" label="姓名" width="auto" min-width="8%">
            <template v-slot="scope">
              <div class="name-column">
                <el-avatar :src="scope.row.avatar" class="user-avatar"></el-avatar>
                <span>{{ scope.row.avatar }}</span><span>{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="工号" width="auto" min-width="7%"></el-table-column>
          <el-table-column prop="sex" label="性别" width="auto" min-width="5%">
            <template v-slot="scope">
              <span v-if="scope.row.sex !== null">
                {{ scope.row.sex === 'man' ? '男' : '女' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="birthdayDate" label="出生日期" width="auto" min-width="10%"
            :formatter="formatBirthdayDate">
          </el-table-column>
          <el-table-column prop="account" label="账号" width="auto" min-width="10%"></el-table-column>
          <el-table-column prop="roles" show-overflow-tooltip label="角色" width="auto" min-width="10%">
            <template v-slot="scope">
              <span>{{ scope.row.roles.map(role => role.roleName).join(', ') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="签名" width="auto" min-width="9%">
            <template v-slot="scope">
              <img :src="scope.row.signImage" class="signature-image" style="height: 26px;" />
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="auto" min-width="8%">
            <template v-slot="scope">
              <span :class="scope.row.status == '1' ? 'status-published' : 'status-abord'">{{
                scope.row.status == '1' ? '已启用' : '已禁用' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="departmentName" label="所属科室" width="auto" show-overflow-tooltip
            min-width="8%"></el-table-column>
          <el-table-column prop="accountExpireDate" label="账号有效截止日期" width="auto" :formatter="formatAccountExpireDate"
            min-width="9%"></el-table-column>
          <el-table-column label="操作" width="auto" min-width="12%" align="center" header-align="center">
            <template v-slot="scope">
              <div class="action-buttons">
                <el-button class="action-button" @click="editUser(scope.row)">
                  <el-icon :size="18">
                    <Edit />
                  </el-icon>
                  <span>编辑</span>
                </el-button>
                <el-button class="action-button" style="margin-left: 2px;" @click="configureUser(scope.row)">
                  <el-icon :size="14">
                    <img width="14" src="@/assets/dun.png" />
                  </el-icon>
                  <span>权限</span>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
          :current-page="currentPage" @current-change="handlePageChange" class="pagination">
        </el-pagination>
        <div class="pagination-info">
          共 {{ total }} 条
        </div>
      </div>
    </div>
    <el-dialog :title="dialogTitle" v-model="showDialog" width="360px" style="border-radius:8px;">
      <el-form :model="currentUser" label-width="80px">
        <el-form-item label="头像">
          <div class="avatar-container">
            <img v-if="currentUser.avatar" :src="currentUser.avatar" style="width: 46px; height: 46px;">
            <el-upload v-else action="#" list-type="picture-card" :limit="1" :on-change="handleAvatarSuccess"
              :file-list="avatarFileList" :auto-upload="false">
              <el-icon class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="currentUser.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            工号 <span class='required-star'>*</span>
          </template>
          <el-input v-model="currentUser.code" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="currentUser.sex">
            <el-radio value="男">男</el-radio>
            <el-radio value="女">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日">
          <el-date-picker v-model="currentUser.birthdayDate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <template #label>
            账号 <span class='required-star'>*</span>
          </template>
          <el-input v-model="currentUser.account" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="currentUser.password" type="password" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="失效日期">
          <el-date-picker v-model="currentUser.accountExpireDate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <template #label>
            角色 <span class='required-star'>*</span>
          </template>
          <el-select v-model="currentUser.roles" multiple placeholder="请选择角色">
            <el-option v-for="role in roleOptions" :key="role.id" :label="role.roleName" :value="role.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签名照片">
          <div class="signature-container">
            <img v-if="currentUser.signImage" :src="currentUser.signImage" style="width: 46px;height: 46px">
            <el-upload v-else action="#" :auto-upload="false" list-type="picture" :limit="1"
              :on-change="handleSignatureSuccess" :file-list="signatureFileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="所属科室">
          <el-select v-model="currentUser.deptId" placeholder="请选择科室">
            <el-option v-for="department in departmentList" :key="department.id" :label="department.name"
              :value="department.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false" class="footer-button">关闭</el-button>
        <el-button type="primary" @click="saveUser" class="footer-button">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="数据权限设置" v-model="showPermissionsDialog" width="620px">
      <div class="permissions-container">
        <div class="permissions-column">
          <h3>科室设备权限</h3>
          <hr class="full-width-line">
          <el-scrollbar style="min-height: 400px; max-height: 400px;">
            <el-tree :data="departmentTree" show-checkbox node-key="id" default-expand-all :props="defaultProps"
              v-model="currentPermissions.departments"></el-tree>
          </el-scrollbar>
        </div>
        <div class="permissions-column" style="margin-left:10px;">
          <h3>报告字段编辑权限</h3>
          <hr class="full-width-line">
          <el-scrollbar style="min-height: 400px; max-height: 400px;">
            <el-checkbox v-model="isAllChecked" @change="toggleAllFields">全选</el-checkbox>
            <el-checkbox-group v-model="currentPermissions.fields">
              <el-checkbox label="患者ID"></el-checkbox>
              <el-checkbox label="患者姓名"></el-checkbox>
              <el-checkbox label="患者来源"></el-checkbox>
              <el-checkbox label="住院号"></el-checkbox>
              <el-checkbox label="条码号"></el-checkbox>
              <el-checkbox label="卡号"></el-checkbox>
              <el-checkbox label="床号"></el-checkbox>
              <el-checkbox label="性别"></el-checkbox>
              <el-checkbox label="出生日期"></el-checkbox>
              <el-checkbox label="诊断"></el-checkbox>
              <el-checkbox label="患者描述"></el-checkbox>
              <el-checkbox label="联系地址"></el-checkbox>
              <el-checkbox label="联系方式"></el-checkbox>
              <el-checkbox label="采样时间"></el-checkbox>
              <el-checkbox label="申请医生"></el-checkbox>
              <el-checkbox label="样本类型"></el-checkbox>
              <el-checkbox label="采样模式"></el-checkbox>
              <el-checkbox label="接收时间"></el-checkbox>
              <el-checkbox label="检测时间"></el-checkbox>
              <el-checkbox label="样本说明"></el-checkbox>
              <el-checkbox label="检测医生"></el-checkbox>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPermissionsDialog = false" class="footer-button">关闭</el-button>
        <el-button type="primary" @click="savePermissions" class="footer-button">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Edit, Setting, Plus, Check, Close, Search } from '@element-plus/icons-vue';
import { useAuthStore } from '@/stores/authStore';
import dayjs from 'dayjs';
import { encryptPassword } from '@/utils/crypto';
export default {
  name: 'UserManagement',
  components: {
    Edit,
    Setting,
    Plus,
    Check,
    Close,
    Search
  },
  data() {
    return {
      isEdit: false,
      searchQuery: '',
      showDialog: false,
      showPermissionsDialog: false,
      dialogTitle: '添加用户',
      dialogVisible: false,
      dialogImageUrl: '',
      currentUser: {},
      hospitalList: [],
      departmentList: [],
      selectedHospitalCode: '',
      currentPermissions: {
        departments: [],
        fields: ['患者ID', '患者姓名', '患者来源', '住院号', '条码号', '卡号', '床号', '性别', '出生日期', '诊断', '患者描述', '联系地址', '联系方式', '采样时间', '申请医生', '样本类型', '采样模式', '接收时间', '检测时间', '样本说明', '检测医生']
      },
      isAllChecked: true,
      avatarFileList: [],
      signatureFileList: [],
      tableData: [],
      departmentTree: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      currentPage: 1,
      pageSize: 14,
      total: 0, // 初始化 total 属性
    };
  },
  computed: {
    filteredTableData() {//暂时屏蔽
      if (this.searchQuery == '' || this.searchQuery.trim() == '') {
        return this.tableData;
      }
      return this.tableData.filter(user => {
        return user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.code.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.account.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    paginatedData() {
      //console.log(this.tableData);
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.tableData;
    }
  },
  methods: {
    formatBirthdayDate(row) {
      if (row.birthdayDate) {
        return dayjs(row.birthdayDate).format('YYYY-MM-DD');
      }
      return '';
    },
    formatAccountExpireDate(row) {
      if (row.accountExpireDate) {
        return dayjs(row.accountExpireDate).format('YYYY-MM-DD');
      }
      return '';
    },
    async syncUsers() {
      try {
        const authStore = useAuthStore();
        await authStore.syncUser(); // 调用 syncUser 方法
        this.$message.success('用户同步成功'); // 提示成功
        this.fetchHospitalList();
        const response = await authStore.getUser({ pageIndex: this.currentPage, pageSize: this.pageSize }); // 调用 getUser 方法获取最新用户数据
        this.tableData = response.data.data; // 更新表格数据
        this.total = response.data.total; // 假设返回的数据中包含 total 字段
      } catch (error) {
        this.$message.error('用户同步失败: ' + error.message);
      }
    },
    async fetchHospitalList() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.hospitallist();
        this.hospitalList = response.data;
        if (this.hospitalList.length > 0) {
          this.selectedHospitalCode = this.hospitalList[0].hospitalCode;
          await this.fetchDepartmentList(this.selectedHospitalCode);
        }
        console.log("hospitalList:", this.hospitalList);
        // 将医院和科室数据转换为树形结构
        this.departmentTree = this.hospitalList.map(hospital => ({
          id: hospital.hospitalCode,
          label: hospital.hospitalName,
          children: (hospital.departments || []).map(department => ({
            id: department.id,
            label: department.name
          }))
        }));
        console.log("departmentTree:", this.departmentTree);
      } catch (error) {
        this.$message.error('获取医院列表失败: ' + error.message);
      }
    },
    async fetchDepartmentList() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.getDepartmentList(this.selectedHospitalCode);
        this.departmentList = response.data;
        this.hospitalList[0].departments = this.departmentList;
        this.fetchUsers(); // 获取用户数据
      } catch (error) {
        this.$message.error('获取科室列表失败: ' + error.message);
      }
    },
    async fetchUsers() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.getUser({ pageIndex: this.currentPage, pageSize: this.pageSize }); // 调用 getUser 方法获取最新用户数据
        this.tableData = response.data.data.map(user => {
          const department = this.departmentList.find(dept => dept.id === user.deptId);
          return {
            ...user,
            departmentName: department ? department.name : ''
          };
        });
        this.total = response.data.total; // 假设返回的数据中包含 total 字段
      } catch (error) {
        this.$message.error('用户列表请求失败 ' + error.message);
      }
    },
    async fetchRoles() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.rolelist(); // 调用 rolelist 方法获取角色数据
        this.roleOptions = response.map(role => ({ roleName: role.roleName, id: role.id })); // 提取角色名称并转换为下拉框选项
      } catch (error) {
        this.$message.error('角色列表请求失败 ' + error.message);
      }
    },
    addUser() {
      // 添加用户处理逻辑
      console.log('Add user');
    },
    editUser(user) {
      // 编辑用户处理逻辑
      this.currentUser = { ...user, roles: user.roles.map(role => role.id) };
      this.avatarFileList = user.avatar ? [{ url: user.avatar }] : [];
      this.signatureFileList = user.signImage ? [{ url: user.signImage }] : [];
      this.openDialog('edit');
    },
    configureUser(user) {
      // 配置用户权限处理逻辑
      console.log('Configure user:', user);
      this.currentPermissions = {
        departments: [],  // 根据实际数据进行初始化
        fields: ['患者ID', '患者姓名', '患者来源', '住院号', '条码号', '卡号', '床号', '性别', '出生日期', '诊断', '患者描述', '联系地址', '联系方式', '采样时间', '申请医生', '样本类型', '采样模式', '接收时间', '检测时间', '样本说明', '检测医生']
      };
      this.isAllChecked = true;
      this.showPermissionsDialog = true;
      // 获取医院和科室的树形结构数据
      this.fetchHospitalList();
    },
    deleteUser(user) {
      // 删除用户处理逻辑
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchUsers(); // 调用 fetchUsers 方法以更新数据和 total 属性
    },
    openDialog(type, user = null) {
      if (type === 'edit') {
        this.dialogTitle = '编辑用户';
        this.isEdit = true;
      } else {
        this.dialogTitle = '添加用户';
        this.currentUser = {};
        this.avatarFileList = [];
        this.signatureFileList = [];
        this.isEdit = false;
      }
      this.showDialog = true;
    },
    async saveUser() {
      // Validate required fields
      if (!this.currentUser.code || !this.currentUser.account || (!this.currentUser.password && !this.isEdit) || !this.currentUser.roles.length) {
        this.$message.error('工号、账号、密码和角色为必填项');
        return;
      }
      const userData = {
        code: this.currentUser.code,
        name: this.currentUser.name,
        account: this.currentUser.account,
        birthdayDate: this.currentUser.birthdayDate ? dayjs(this.currentUser.birthdayDate).format('YYYY-MM-DD') : null,
        status: 1, // Assuming status is always 1 for new users
        deptId: this.currentUser.deptId,
        sex: this.currentUser.sex,
        roles: this.currentUser.roles.map(roleId => {
          const role = this.roleOptions.find(r => r.value === roleId);
          return {
            id: roleId,
            roleName: role ? role.roleName : '',
          };
        }),
        accountExpireDate: this.currentUser.accountExpireDate ? dayjs(this.currentUser.accountExpireDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,
        password: this.currentUser.password,
        avatar: this.currentUser.avatar,
        signImage: this.currentUser.signImage
      };
      const publicKey = '049b1992cb81ce0f6776b1a91a253c34405851199b36e6e99c2b39a0d76140b183635519ad2f171bc9dcd33042c62a91c795efff219d04ab1ecfd7920a8b2a0afe'; // Replace with the actual public key
      if (userData.password) {
        userData.password = encryptPassword(userData.password, publicKey);
      }
      try {
        const authStore = useAuthStore();
        let response;
        if (this.currentUser.id) {
          response = await authStore.updateUser(this.currentUser.id, userData);
        } else {
          response = await authStore.addUser(userData);
        }
        console.log(response);
        if (response.msg === "success") {
          this.$message.success('用户保存成功');
          this.showDialog = false;
          this.fetchUsers();
        }
      } catch (error) {
        this.$message.error('用户保存失败: ' + error.message);
      }
    },
    savePermissions() {
      console.log('保存权限:', this.currentPermissions);
      this.showPermissionsDialog = false;
    },
    toggleAllFields() {
      if (this.isAllChecked) {
        this.currentPermissions.fields = ['患者ID', '患者姓名', '患者来源', '住院号', '条码号', '卡号', '床号', '性别', '出生日期', '诊断', '患者描述', '联系地址', '联系方式', '采样时间', '申请医生', '样本类型', '采样模式', '接收时间', '检测时间', '样本说明', '检测医生'];
      } else {
        this.currentPermissions.fields = [];
      }
    },
    handleAvatarSuccess(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.currentUser.avatar = e.target.result; // 将 Base64 数据赋值给 avatar
      };
      reader.readAsDataURL(file.raw); // 读取文件并转换为 Base64
    },
    handleSignatureSuccess(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.currentUser.signImage = e.target.result; // 将 Base64 数据赋值给 signImage
      };
      reader.readAsDataURL(file.raw);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    }
  },

  mounted() {
    this.fetchHospitalList();
    this.fetchUsers(); // 组件挂载时获取用户数据
    this.fetchRoles();
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.user-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.user-management-box {
  width: calc(100% - 20px);
  height: calc(100vh - 40px);
  background-color: #F0F3F5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #057AB2;
}

.header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.actions {
  display: flex;
  align-items: center;
}

.search-input {
  margin-right: 10px;
}

.custom-button1 {
  background-color: white;
  color: black;
  border-color: #F0F3F5;
}

.custom-button {
  background-color: #057AB2;
  color: white;
  border: none;
}

.custom-button:hover {
  background-color: #045a8d;
  border: none;
}

.name-column {
  display: flex;
  align-items: center;
}

.user-avatar {
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  overflow: auto;
}

.action-icon {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.status-published {
  color: #56B22B;
}

.status-abord {
  color: red;
}

.dialog-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.action-buttons .action-button {
  border: none;
  color: #057AB2;
  width: 68px;
}

:deep(.avatar-container .el-upload--picture-card),
:deep(.avatar-container .el-upload-list__item),
:deep(.avatar-container .el-upload-list__item-actions) {
  width: 48px;
  height: 48px;
}

:deep(.signature-container .el-upload--picture-card, .el-upload-list__item.el-upload-list__item-actions) {
  height: 20px;
}

:deep(.signature-container .el-upload-list--picture .el-upload-list__item-thumbnail) {
  height: 20px;
}

.action-button:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057AB2;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
  background-color: rgba(0, 0, 0, 0) !important;
}

.pagination-container {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.permissions-container {
  display: flex;
  border-radius: 8px;
}

.permissions-column {
  background-color: #F0F3F5;
  border: #F0F3F5 1px solid;
  border-radius: 8px;
  flex: 1;
  padding: 0px 10px 10px 10px;
  min-height: 300px;
}

.permissions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-width-line {
  width: 100%;
  border: none;
  border-bottom: 1px solid #DCDCDC;
  margin: 10px 0;
}

.footer-button {
  width: 88px;
  height: 32px;
}

:deep(.el-dialog__title) {
  font-size: 20px;
  font-weight: 700;
}

.required-star {
  color: red;
}
</style>
