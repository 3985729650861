// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import UserManagement from '@/views/system/UserManagement.vue';
import RoleManagement from '@/views/system/RoleManagement.vue';
import DeviceManagement from '@/views/system/DeviceManagement.vue';
import ParameterManagement from '@/views/system/ParameterManagement.vue';
import TemplateManagement from '@/views/system/TemplateManagement.vue';
import DepartmentManagement from '@/views/system/DepartmentManagement.vue';
import DictionaryManagement from '@/views/system/DictionaryManagement.vue';
import RegistrationManager from '@/views/report/RegistrationManager.vue';
import ReportManager from '@/views/report/ReportManager.vue';
import QueryManager from '@/views/report/QueryManager.vue';
import Setting from '@/views/system/Setting.vue';
import DocumentManager from '@/views/document/Document.vue';
import { useAuthStore } from '@/stores/authStore';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { createApp } from 'vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }, // 添加需要认证的标识
    children: [
      { path: 'Usermanagement', name: 'UserManagement', component: UserManagement },
      { path:'setting',name:'Setting',component:Setting},
      { path:'RoleManagement',name:'RoleManagement',component:RoleManagement},
      { path:'DeviceManagement',name:'DeviceManagement',component:DeviceManagement},
      { path:'ParameterManagement',name:'ParameterManagement',component:ParameterManagement},
      { path:'TemplateManagement',name:'TemplateManagement',component:TemplateManagement},
      { path:'DepartmentManagement',name:'DepartmentManagement',component:DepartmentManagement},
      { path:'DictionaryManagement',name:'DictionaryManagement',component:DictionaryManagement},
      { path:'RegistrationManager',name:'RegistrationManager',component:RegistrationManager},
      { path:'ReportManager',name:'ReportManager',component:ReportManager},
      { path:'QueryManager',name:'QueryManager',component:QueryManager},
      { path:'CriticalManager',name:'CriticalManager',component:()=> import('@/views/report/CriticalManager.vue')},
      { path:'ReportSetting',name:'ReportSetting',component:()=> import('@/views/report/Setting.vue')},
      { path:'DocumentManager',name:'DocumentManager',component:DocumentManager},
      { path:'ZScore',name:'ZScore',component:()=> import('@/views/quality/ZScore.vue')},
      { path:'QualityProduct',name:'QualityProduct',component:()=> import('@/views/quality/product.vue')},
      { path:'QualityChannel',name:'QualityChannel',component:()=> import('@/views/quality/channel.vue')},
      { path:'QualityRange',name:'QualityRange',component:()=> import('@/views/quality/range.vue')},
      { path:'SampleMonitor',name:'SampleMonitor',component:()=> import('@/views/data/sampleMonitor.vue')},
      { path:'ReplacementReport',name:'ReplacementReport',component:()=> import('@/views/data/replacementReport.vue')},
      { path:'DeviceData',name:'DeviceData',component:()=> import('@/views/data/device.vue')},
      { path:'ProductChart',name:'ProductChart',component:()=> import('@/views/quality/productChart.vue')},
      { path:'ClinicReview',name:'ClinicReview',component:()=> import('@/views/data/clinicReview.vue')},
      { path:'RangeSetting',name:'RangeSetting',component:()=> import('@/views/instrument/rangeSetting.vue')},
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 创建一个 Vue 实例来使用 Pinia
const app = createApp({});
app.use(PiniaVuePlugin);
const pinia = createPinia();
app.use(pinia);

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  // 加载 token
  authStore.loadToken();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果需要认证，检查是否已登录
    if (!authStore.isAuthenticated) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
