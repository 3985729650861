<template>
  <div class="device-management-container">
    <div class="device-management-box">
      <!-- 标题部分 -->
      <div class="header">
        <span class="title">设备管理</span>
        <el-input v-model="search" placeholder="设备名称或安装号过滤" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="SyncDevice" class="sync-button">同步设备</el-button>
      </div>
      <!-- 设备表格部分 -->
      <div class="table-container">
        <el-table :data="paginatedDevices" class="custom-table" style="width: 100%;border:none;"
          :header-cell-style="{ background: '#F5F6F7' }">
          <el-table-column prop="name" label="设备名称" width="auto" min-width="20%"></el-table-column>
          <el-table-column prop="deptName" label="科室" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="installNum" label="安装号" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="typeName" label="类型" width="auto" min-width="10%"></el-table-column>
          <el-table-column label="使用码" width="auto" min-width="10%">
            <template v-slot="scope">
              <el-switch v-model="scope.row.useSpecimen" :active-value="1" :inactive-value="0"
                @change="value => {scope.row.useSpecimen = value;updateDevice(scope.row);}"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="启用" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0"
                         @change="value => { scope.row.status = value; updateDevice(scope.row); }"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="报告模板" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <el-select v-model="scope.row.printTmplId" placeholder="请选择"
                         @change="value => { scope.row.printTmplId = value; updateDevice(scope.row); }">
                <el-option v-for="template in templates" :key="template.name" :label="template.name"
                           :value="template.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <el-button style="border: none;color:#057AB2;" class="action-button" @click="editDevice(scope.row)">
                <el-icon size="16">
                  <EditPen />
                </el-icon>
                参数设置
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页部分 -->
<!--      <div class="pagination-container">-->
<!--        <el-pagination background layout="prev, pager, next" :total="filteredDevices.length" :page-size="pageSize"-->
<!--          @current-change="handlePageChange" />-->
<!--        <div class="pagination-info">-->
<!--          共 {{ filteredDevices.length }} 条-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!-- 参数设置抽屉 -->
    <div class="drawer-wrapper">
      <el-drawer v-model="drawerVisible" direction="btt" :wrapperClosable="false" :size="getDrawerHeight()"
        :style="getDrawerStyle()">
        <template #header>
          <div class="drawer-header">
            <div class="drawer-title-section">
              <span class="drawer-title">参数设置</span>
              <span style="margin-left: 15px;font-weight: 400;color:#5E6266;">设备名称: </span>
              <span style="margin-left:10px;font: 500;color:#000;">{{ currentDevice.name }}</span>
              <span style="margin-left:15px;font-weight: 400;color:#5E6266;">科室: </span>
              <span style="margin-left:10px;font: 500;color:#000;">{{ currentDevice.department }}</span>
            </div>
            <div class="drawer-actions">
              <el-button class="action1" @click="editAllParameters">{{ isAllEditing ? '取消编辑' : '全部编辑' }}</el-button>
              <el-button class="action1" @click="openSyncDrawer">同步参数</el-button>
              <el-button :class="{ 'remove-margin-right': showSaveButton }" class="action3"
                @click="copyParameters">复制参数</el-button>
              <el-button v-if="isAllEditing || isSyncDrawer || showSaveButton" class="action2" type="success"
                @click="saveParameters">保存</el-button>
            </div>
          </div>
        </template>
        <div class="drawer-content">
          <el-table :data="paginatedParameters" :row-class-name="tableRowClassName" style="width: 100%;"
            :header-cell-style="{ background: '#F5F6F7' }">
            <el-table-column prop="index" label="序号" width="60">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.index" />
                </div>
                <div v-else>{{ scope.row.index }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="parameter" label="参数" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.parameter" />
                </div>
                <div v-else>{{ scope.row.parameter }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="standardValue" label="标准参数" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-select v-model="scope.row.standardValue" placeholder="请选择">
                    <el-option v-for="value in standardValues" :key="value" :label="value" :value="value"></el-option>
                  </el-select>
                </div>
                <div v-else>{{ scope.row.standardValue }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="projectName" label="项目名称" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.projectName" />
                </div>
                <div v-else>{{ scope.row.projectName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="unit" label="单位" width="80">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.unit" />
                </div>
                <div v-else>{{ scope.row.unit }}</div>
              </template>
            </el-table-column>
            <el-table-column label="启用" width="80">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-switch v-model="scope.row.enabled" />
                </div>
                <div v-else>
                  <el-switch v-model="scope.row.enabled" disabled />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="parameterType" label="参数类型" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-select v-model="scope.row.parameterType" placeholder="请选择">
                    <el-option v-for="type in parameterTypes" :key="type" :label="type" :value="type"></el-option>
                  </el-select>
                </div>
                <div v-else>{{ scope.row.parameterType }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="calculationType" label="计算类型" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-select v-model="scope.row.calculationType" placeholder="请选择">
                    <el-option v-for="type in calculationTypes" :key="type" :label="type" :value="type"></el-option>
                  </el-select>
                </div>
                <div v-else>{{ scope.row.calculationType }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="paymentItem" label="付费项目" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-select v-model="scope.row.paymentItem" placeholder="请选择">
                    <el-option v-for="item in paymentItems" :key="item" :label="item" :value="item"></el-option>
                  </el-select>
                </div>
                <div v-else>{{ scope.row.paymentItem }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="referenceLower" label="参考下限" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.referenceLower" />
                </div>
                <div v-else>{{ scope.row.referenceLower }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="referenceUpper" label="参考上限" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.referenceUpper" />
                </div>
                <div v-else>{{ scope.row.referenceUpper }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="criticalLower" label="危急值下限" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.criticalLower" />
                </div>
                <div v-else>{{ scope.row.criticalLower }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="criticalUpper" label="危急值上限" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.criticalUpper" />
                </div>
                <div v-else>{{ scope.row.criticalUpper }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="displayDecimals" label="显示小数位数" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input-number v-model="scope.row.displayDecimals" :min="0" :step="1"></el-input-number>
                </div>
                <div v-else>{{ scope.row.displayDecimals }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="auto" min-width="200" align="center" header-align="center">
              <template v-slot="scope">
                <div v-if="!isAllEditing && scope.row.editable" class="action-buttons">
                  <el-button @click="confirmEditing(scope.row)" class="action-button">
                    <el-icon size="16">
                      <Check />
                    </el-icon>
                    确认
                  </el-button>
                  <el-button @click="cancelEditing(scope.row)" class="action-button">
                    <el-icon size="16">
                      <Close />
                    </el-icon>
                    取消
                  </el-button>
                </div>
                <div v-if="!isAllEditing && !scope.row.editable" class="action-buttons">
                  <el-button @click="startEditing(scope.row)" class="action-button">
                    <el-icon size="16">
                      <EditPen />
                    </el-icon>
                    编辑
                  </el-button>
                  <el-button @click="specialParameters(scope.row)" class="action-button" style="margin-left:2px;">
                    <img src="@/assets/setting.png" width="16" height="16" style="margin-right: 3px" />特殊参数
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="drawer-pagination-container">
            <el-pagination background layout="prev, pager, next" :total="currentDeviceParameters.length"
              :page-size="drawerPageSize" @current-change="handleDrawerPageChange" />
          </div>
        </div>
      </el-drawer>
    </div>

    <!-- 复制参数对话框 -->
    <el-dialog title="复制参数" v-model="copyDialogVisible" width="620px" style="border-radius: 8px;"
      class="copy-para-content">
      <div class="copy-parameters-header">
        <span>从设备</span>
        <el-select v-model="selectedDevice" placeholder="请输入关键字" class="dialog-select">
          <el-option v-for="device in devices" :key="device.installNumber" :label="device.name"
            :value="device"></el-option>
        </el-select>
        <span style="margin-left:15px;">复制到当前 </span><span style="margin-left:20px;">{{ currentDevice.name }} - {{
          currentDevice.department }}</span>
      </div>
      <div class="copy-parameters-container">
        <div class="parameters-box">
          <div class="parameters-header">
            <span>源设备参数</span>
          </div>
          <el-table ref="sourceTable" :data="filteredSourceParameters" height="300" class="copy-para-table">
            <el-table-column type="selection" width="26"></el-table-column>
            <el-table-column prop="name" label="参数名称"></el-table-column>
          </el-table>
        </div>
        <div class="copy-buttons">
          <el-button class="action1" @click="moveParameters('toSource')">&lt;</el-button>
          <el-button class="action1" @click="moveParameters('toTarget')">&gt;</el-button>
        </div>
        <div class="parameters-box">
          <div class="parameters-header">
            <span>目标设备参数</span>
          </div>
          <el-table ref="targetTable" :data="targetParameters" height="300" class="copy-para-table">
            <el-table-column type="selection" width="26"></el-table-column>
            <el-table-column prop="name" label="参数名称"></el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyDialogVisible = false" style="width: 88px;">关闭</el-button>
        <el-button type="primary" @click="saveCopiedParameters" style="width: 88px;" class="action1">保存</el-button>
      </div>
    </el-dialog>

    <!-- 特殊参数对话框 -->
    <el-dialog title="特殊参数" v-model="specialParamsDialogVisible" width="620px"
      :style="{ borderRadius: '8px', height: '600px' }" :header-style="{ background: '#f5f6f7' }">
      <template #header>
        <div class="dialog-title">
          <span>特殊参数</span>
          <el-button type="primary" @click="openSpecialRangeDialog" class="title-button">添加设置</el-button>
        </div>
      </template>
      <el-table :data="specialParams" class="special-params-table" style="width: 100%;"
        :header-cell-style="{ background: '#f5f6f7' }" :fit="true">
        <el-table-column prop="range" label="范围" width="320"></el-table-column>
        <el-table-column prop="condition" label="条件"></el-table-column>
        <el-table-column label="操作" align="center" header-align="center">
          <template v-slot="scope">
            <el-button @click="removeSpecialParam(scope.$index)" type="text">
              <el-icon size="16" color="black">
                <Close />
              </el-icon>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 特殊范围设置对话框 -->
    <el-dialog title="特殊范围设置" v-model="specialRangeDialogVisible" width="640px"
      :style="{ borderRadius: '8px', height: '380px' }" :header-style="{ background: '#f5f6f7', fontWeight: '700' }">
      <div class="range-setting-content">
        <div class="range-setting-section">
          <div class="section-header">
            <h3>范围</h3>
            <el-button type="text" @click="addRange">
              <el-icon size="16" color="black">
                <Plus />
              </el-icon>
            </el-button>
          </div>
          <el-scrollbar height="160px">
            <div v-for="(range, index) in ranges" :key="index" class="range-row">
              <el-select v-model="range.field" placeholder="请选择范围">
                <el-option label="参考下限" value="参考下限"></el-option>
                <el-option label="参考上限" value="参考上限"></el-option>
                <el-option label="危急值下限" value="危急值下限"></el-option>
                <el-option label="危急值上限" value="危急值上限"></el-option>
              </el-select><span style="margin-left:2px;margin-right: 2px;">=</span>
              <el-input v-model="range.value" placeholder="值"></el-input>
              <el-button type="text" @click="removeRange(index)">
                <el-icon size="16" color="black">
                  <Minus />
                </el-icon>
              </el-button>
            </div>
          </el-scrollbar>
        </div>
        <div class="rule-setting-section">
          <div class="section-header">
            <h3>规则</h3>
            <el-button type="text" @click="addRule">
              <el-icon size="16" color="black">
                <Plus />
              </el-icon>
            </el-button>
          </div>
          <el-scrollbar height="160px">
            <div v-for="(rule, index) in rules" :key="index" class="rule-row">
              <el-select v-model="rule.field" placeholder="请选择">
                <el-option label="性别" value="性别"></el-option>
                <!-- 其他选项可以根据需要添加 -->
              </el-select>
              <el-select v-model="rule.operator" placeholder="请选择">
                <el-option label="=" value="="></el-option>
                <!-- 其他操作符可以根据需要添加 -->
              </el-select>
              <el-input v-model="rule.value" placeholder="值"></el-input>
              <el-button type="text" @click="removeRule(index)">
                <el-icon size="16" color="black">
                  <Minus />
                </el-icon>
              </el-button>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div slot="footer" class="dialog-footer1">
        <el-button @click="closeSpecialRangeDialog">关闭</el-button>
        <el-button type="primary" @click="saveSpecialRangeSetting" class="action1">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="设备同步确认" v-model="syncDialogVisible" width="720px" style="border-radius: 8px">
      <el-table :data="newDevices" style="width: 100%;">
        <el-table-column prop="name" label="设备名称" width="auto" min-width="20%"></el-table-column>
        <el-table-column prop="deptName" label="科室" width="auto" min-width="20%"></el-table-column>
        <el-table-column prop="typeName" width="auto" min-width="20%" label="类型"></el-table-column>
        <el-table-column prop="installNum" label="安装号" width="auto" min-width="30%"></el-table-column>
        <el-table-column label="状态" width="auto" min-width="10%">
          <template v-slot="scope">
            <span style="color: green;">新增</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
    <el-button @click="syncDialogVisible = false" style="margin-top: 10px;width: 88px">关闭</el-button>
    <el-button type="primary" @click="saveNewDevices" style="margin-top: 10px;width: 88px">保存</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { Search, EditPen, Check, Close, Plus, Minus } from '@element-plus/icons-vue';
import { useAuthStore } from '@/stores/authStore';
export default {
  components: {
    Search,
    EditPen,
    Check,
    Close,
    Plus,
    Minus
  },
  props: {
    isAsideCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      syncDialogVisible:false,
      newDevices:[],
      search: '',
      devices: [],
      templates: [],
      drawerVisible: false,
      currentDevice: {},
      isSyncDrawer: false,
      currentDeviceParameters: [
        { index: 1, parameter: '参数1', standardValue: '标准值1', projectName: '项目1', unit: '单位1', enabled: true, parameterType: '类型1', calculationType: '计算类型1', paymentItem: '付费项目1', referenceLower: '下限1', referenceUpper: '上限1', criticalLower: '危急下限1', criticalUpper: '危急上限1', displayDecimals: 2, editable: false },
        { index: 2, parameter: '参数2', standardValue: '标准值2', projectName: '项目2', unit: '单位2', enabled: false, parameterType: '类型2', calculationType: '计算类型2', paymentItem: '付费项目2', referenceLower: '下限2', referenceUpper: '上限2', criticalLower: '危急下限2', criticalUpper: '危急上限2', displayDecimals: 1, editable: false },
      ],
      standardValues: ['标准值1', '标准值2', '标准值3'],
      parameterTypes: ['类型1', '类型2', '类型3'],
      calculationTypes: ['计算类型1', '计算类型2', '计算类型3'],
      paymentItems: ['付费项目1', '付费项目2', '付费项目3'],
      currentPage: 1,
      pageSize: 200,
      drawerPageSize: 5,
      drawerCurrentPage: 1,
      editingRow: null,
      isAllEditing: false,
      originalParameters: [],
      copyDialogVisible: false,
      selectedDevice: null,
      sourceDeviceSearch: '',
      filterByName: false,
      filterByNameTarget: false,
      sourceParameters: [
        { name: 'CKMB [肌酸激酶同工酶]' },
        { name: 'CRP [C反应蛋白]' },
        { name: 'D-dimer [D-二聚体测定]' },
        { name: 'Myo [肌红蛋白]' },
      ],
      targetParameters: [],
      specialParamsDialogVisible: false,
      specialRangeDialogVisible: false,
      specialParams: [
        { range: '参考下限=40 且 参考上限=80', condition: '性别=女' },
      ],
      ranges: [
        { field: '', value: '' },
      ],
      rules: [
        { field: '性别', operator: '=', value: '' },
      ],
      showSaveButton: false
    };
  },
  computed: {
    // 过滤设备数据
    filteredDevices() {
      if (this.search) {
        return this.devices.filter(device =>
          device.name.includes(this.search) || device.installNumber.includes(this.search)
        );
      }
      return this.devices;
    },
    // 分页显示设备数据
    paginatedDevices() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.filteredDevices.slice(start, end);
    },
    // 分页显示参数数据
    paginatedParameters() {
      const start = (this.drawerCurrentPage - 1) * this.drawerPageSize;
      const end = this.drawerCurrentPage * this.drawerPageSize;
      return this.currentDeviceParameters.slice(start, end);
    },
    // 过滤源设备参数数据
    filteredSourceParameters() {
      if (this.filterByName) {
        return this.sourceParameters.filter(param =>
          param.name.includes(this.sourceDeviceSearch)
        );
      }
      return this.sourceParameters;
    },
  },
  watch: {
    // 监听侧边栏折叠状态变化，更新抽屉宽度
    isAsideCollapsed() {
      this.updateDrawerWidth();
    },
  },
  methods: {
    // 获取抽屉宽度和高度
    getDrawerStyle() {
      return {
        width: this.getDrawerWidth(),
        marginTop: '70px',
        borderRadius: '12px',
        position: 'absolute',
        right: '20px'
      };
    },
    // 获取抽屉宽度
    getDrawerWidth() {
      return this.isAsideCollapsed ? 'calc(100% - 88px)' : 'calc(100% - 220px)';
    },
    getDrawerHeight() {
      return 'calc(100% - 70px)';
    },
    // 打开同步设备抽屉
    openSyncDrawer() {
      this.isSyncDrawer = true;
      this.drawerVisible = true;
      this.showSaveButton = true;
    },
    async SyncDevice() {
      // 此处同步设备
      try {
        await this.fetchDeviceList();
        const authStore = useAuthStore();
        // 再获取同步设备列表
        const syncResponse = await authStore.fetchDeviceList();
        if (syncResponse.code === 200) {
          this.newDevices = syncResponse.data.filter(syncDevice =>
              !this.devices.some(existingDevice => existingDevice.id === syncDevice.id)
          );

          if (this.newDevices.length > 0) {
            this.syncDialogVisible=true;
          } else {
            this.$message.info('没有设备需要同步');
          }
        }
      } catch (error) {
        console.error('Failed to sync device list:', error);
      }
    },
    async saveNewDevices() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.addDevices(this.newDevices); // Call the new addDevices method
        if (response.code === 200) {
          this.$message.success('新增设备保存成功');
          this.syncDialogVisible = false; // 关闭弹窗
          this.newDevices = []; // 清空新增设备列表
          await this.fetchDeviceList(); // 重新加载设备列表
        } else {
          this.$message.error('保存设备失败');
        }
      } catch (error) {
        console.error('Failed to save new devices:', error);
        this.$message.error('保存设备失败');
      }
    },
    async fetchDeviceList() {
      try {
        const authStore = useAuthStore();
        const deviceListResponse = await authStore.devicelist();
        if (deviceListResponse.code === 200) {
          this.devices = deviceListResponse.data;
        } else {
          this.$message.error('获取设备列表失败');
        }
      } catch (error) {
        console.error('Failed to fetch device list:', error);
        this.$message.error('获取设备列表失败');
      }
    },
  
    async updateDevice(device) {
      const authStore = useAuthStore();
      try {
        const response = await authStore.updateDevice(device.id, {
          id: device.id,
          code: device.code,
          name: device.name,
          deptId: device.deptId,
          printTmplId: device.printTmplId,
          installNum: device.installNum,
          status: device.status,
          useSpecimen: device.useSpecimen,
          ipAddress: device.ipAddress,
          typeId: device.typeId,
          typeName: device.typeName
        });
        if (response.code === 200) {
          this.$message.success('设备信息更新成功');
        } else {
          this.$message.error('设备信息更新失败');
        }
      } catch (error) {
        console.error('Failed to update device:', error);
        this.$message.error('设备信息更新失败');
      }
    },
    async fetchTemplates() {
      const authStore = useAuthStore();
      try {
        const response = await authStore.getTemplateList();
        if (response.code === 200) {   
          this.templates = response.data.data;
          console.log("templates:",this.templates);
        }
      } catch (error) {
        console.error('Failed to fetch templates:', error);
      }
    },
    // 编辑设备
    editDevice(device) {
      this.currentDevice = device;
      this.isSyncDrawer = false;
      this.drawerVisible = true;
      this.showSaveButton = false;
      this.updateDrawerWidth();
    },
    // 开始编辑某行参数
    startEditing(row) {
      if (!row.original) {
        row.original = JSON.parse(JSON.stringify(row));
      }
      this.editingRow = row;
      row.editable = true;
    },
    // 确认编辑某行参数
    confirmEditing(row) {
      this.editingRow = null;
      row.editable = false;
      row.original = null;
    },
    // 取消编辑某行参数
    cancelEditing(row) {
      if (row.original) {
        Object.assign(row, row.original);
        row.original = null;
      }
      this.editingRow = null;
      row.editable = false;
      this.showSaveButton = false;
    },
    // 处理分页变化
    handlePageChange(page) {
      this.currentPage = page;
    },
    // 处理抽屉分页变化
    handleDrawerPageChange(page) {
      this.drawerCurrentPage = page;
    },
    // 根据行状态设置行样式
    tableRowClassName({ row, rowIndex }) {
      if (this.isSyncDrawer && rowIndex === 0) {
        return 'highlight-row';
      }

      if (row.editable) {
        return 'editing-row';
      }
      return '';
    },

    // 更新抽屉宽度
    updateDrawerWidth() {
      this.drawerWidth = this.getDrawerWidth();
    },
    // 全部编辑参数
    editAllParameters() {
      this.isAllEditing = !this.isAllEditing;
      if (this.isAllEditing) {
        this.originalParameters = JSON.parse(JSON.stringify(this.currentDeviceParameters));
        this.showSaveButton = true;
      } else {
        this.currentDeviceParameters = JSON.parse(JSON.stringify(this.originalParameters));
        this.showSaveButton = false;
      }
      this.currentDeviceParameters.forEach(param => {
        param.editable = this.isAllEditing;
      });
    },
    // 同步参数
    syncParameters() {
      console.log('同步参数');
    },
    // 复制参数
    copyParameters() {
      this.copyDialogVisible = true;
    },
    // 保存参数
    saveParameters() {
      console.log('保存参数');
      if (this.isSyncDrawer) {
        // 同步设备逻辑
        console.log('保存同步设备的改变');
      } else {
        // 全部编辑保存逻辑
        console.log('保存全部编辑的改变');
      }
      this.isAllEditing = false;
      this.showSaveButton = false;
      this.isSyncDrawer = false;
      this.drawerVisible = true;
      this.currentDeviceParameters.forEach(param => {
        param.editable = false;
        param.original = null;
      });
    },
    // 移动参数
    moveParameters(direction) {
      const sourceSelections = this.$refs.sourceTable.getSelectionRows();
      const targetSelections = this.$refs.targetTable.getSelectionRows();

      if (direction === 'toTarget') {
        this.targetParameters.push(...sourceSelections);
        this.sourceParameters = this.sourceParameters.filter(param => !sourceSelections.includes(param));
      } else {
        this.sourceParameters.push(...targetSelections);
        this.targetParameters = this.targetParameters.filter(param => !targetSelections.includes(param));
      }
    },
    // 保存复制的参数
    saveCopiedParameters() {
      console.log('保存复制的参数');
      this.copyDialogVisible = false;
    },
    // 打开特殊参数对话框
    specialParameters(row) {
      this.specialParamsDialogVisible = true;
    },
    // 添加特殊参数
    openSpecialRangeDialog() {
      this.specialParamsDialogVisible = false;
      this.specialRangeDialogVisible = true;
    },
    closeSpecialRangeDialog() {
      this.specialRangeDialogVisible = false;
    },
    addSpecialParam() {
      this.specialParams.push({
        range: '',
        condition: ''
      });
    },
    // 删除特殊参数
    removeSpecialParam(index) {
      this.specialParams.splice(index, 1);
    },
    addRange() {
      this.ranges.push({ field: '', value: '' });
    },
    removeRange(index) {
      this.ranges.splice(index, 1);
    },
    addRule() {
      this.rules.push({ field: '性别', operator: '=', value: '' });
    },
    removeRule(index) {
      this.rules.splice(index, 1);
    },
    saveSpecialRangeSetting() {
      // 处理保存逻辑
      this.closeSpecialRangeDialog();
    }
  },
  async mounted() {
    await this.fetchTemplates();
    await this.fetchDeviceList();
  }
};
</script>

<style scoped>
/* 样式 */
* {
  box-sizing: border-box;
}

.device-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.device-management-box {
  width: calc(100% - 20px);
  height: calc(100vh - 40px);
  background-color: #f0f3f5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  color: #057ab2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.sync-button {
  margin-left: 20px;
  background-color: #057ab2;
  border-color: #057ab2;
}

.sync-button:hover {
  background-color: #005f8a;
  border-color: #005f8a;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.drawer-title-section {
  display: flex;
  align-items: center;
}

.drawer-title {
  color: #057ab2;
}

.drawer-actions {
  display: flex;
}

.drawer-actions .action1 {
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
}

.drawer-actions .action1:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

.drawer-actions .action3 {
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
  margin-right: 40px;
}

.drawer-actions .action3:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

.drawer-actions .remove-margin-right {
  margin-right: 0 !important;
}

.drawer-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 20px;
  border-top: 1px solid #ebeef5;
  background-color: #fff;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.pagination-container {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  overflow: auto;
}

.drawer-pagination-container {
  text-align: center;
  margin-top: 10px;
}

.action-buttons .action-button {
  border: none;
  color: #057ab2;
  background-color: transparent;
}

.action-button:hover {
  background-color: rgba(5, 122, 178, 0.25);
  border: 1px solid rgba(5, 122, 178, 0.25);
  color: #057ab2;
}

:deep(.highlight-row) {
  background-color: #fff8dc !important;
}

:deep(.editing-row) {
  background-color: #c0ddeb !important;
}

.drawer-content .el-input-number {
  width: 100px;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
  background-color: rgba(0, 0, 0, 0) !important;
}

.copy-parameters-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-left: 10px;
}

.copy-parameters-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.parameters-box {
  display: flex;
  flex-direction: column;
  width: 40%;
  border-radius: 8px;
  border: #C2C3C4 1px solid;
  overflow: auto;
}

.parameters-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: #C2C3C4 1px solid;
  background-color: #f0f3f5;
}

.copy-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.dialog-select {
  width: 184px;
  margin-left: 4px;
}

.copy-para-content {
  border-radius: 8px;
}

:deep(.copy-para-content .el-table td.el-table__cell),
:deep(.copy-para-content .el-table th.el-table__cell.is-leaf) {
  border-bottom: none;
}

:deep(.copy-para-content .el-table__inner-wrapper:before) {
  height: 0;
}

.copy-para-table {
  font-size: 12px;
  color: #5E6266;
}

:deep(.parameters-box .el-table .el-table__cell) {
  padding: 0px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.dialog-footer .action1,
.copy-buttons .action1 {
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
}

.dialog-footer .action1:hover,
.copy-buttons .action1:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

/* 特殊参数对话框样式 */
.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title-button {
  margin-left: auto;
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
  width: 88px;
}

.title-button:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

.special-params-table {
  width: 100%;
  border: none;
}

.el-dialog {
  border-radius: 8px;
}

.el-dialog__header {
  background-color: #f5f6f7;
}

.el-table__header-wrapper th {
  background-color: #f5f6f7;
}

/*特殊范围设置*/
.range-setting-content {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}

.range-setting-section {
  width: 240px;
  height: 240px;
  background-color: #f0f3f5;
  border-radius: 8px;
  padding: 10px;
}

.rule-setting-section {
  width: 344px;
  height: 240px;
  background-color: #f0f3f5;
  border-radius: 8px;
  padding: 10px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.range-row,
.rule-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.el-button[type="text"] {
  margin-left: 10px;
}

.dialog-footer1 {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: white;
  position: absolute;
  bottom: 10px;
  right: 20px;
  left: 20px;
}

.dialog-footer1 .el-button {
  width: 88px;
}

.dialog-footer1 .action1 {
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
}

.dialog-footer1 .action1:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

.action2 {
  width: 88px;
  margin-right: 40px;
}

:deep(.el-drawer.btt) {
  left: auto;
  right: 0;
}
</style>