<template>
  <el-aside :width="isCollapse ? '68px' : '200px'" class="sidebar-container">
    <div class="sidebar-header">
      <span v-if="systemLogoBase64 && !isCollapse" class="sidebar-title">
        <img :src="systemLogoBase64" alt="Logo" class="sidebar-logo" />
      </span>
      <el-button @click="toggleSidebar" class="toggle-button" circle>
        <el-icon :size="20" color="#C2C3C4">
          <component :is="toggleIcon" />
        </el-icon>
      </el-button>
    </div>
    <div :class="isCollapse ? 'separator1' : 'separator'"></div>
    <el-scrollbar class="scrollbar" :wrap-style="{ height: 'calc(100% - 70px)' }">
      <el-menu default-active="1-1" class="el-menu-vertical-demo"
        :class="isCollapse ? 'el-menu-collapsed' : 'el-menu-expanded'" :collapse="isCollapse" background-color="#474747"
        text-color="#C2C3C4" active-text-color="#fff" @open="handleOpen" @close="handleClose">
        <el-sub-menu index="1" v-if="hasPermission('样本中心')">
          <template #title>
            <el-icon>
              <Document />
            </el-icon>
            <span>样本中心</span>
          </template>
          <el-menu-item index="1-1" @click="gotoRegM" v-if="hasPermission('登记')">登记</el-menu-item>
          <el-menu-item index="1-2" @click="gotoReportM" v-if="hasPermission('报告')">报告</el-menu-item>
          <el-menu-item index="1-3" @click="gotoQueryM" v-if="hasPermission('查询')">查询</el-menu-item>
          <el-menu-item index="1-4" @click="gotoCri" v-if="hasPermission('危急值')">危急值</el-menu-item>
          <el-menu-item index="1-5" @click="gotoReportS" v-if="hasPermission('报告设置')">设置</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="2" v-if="hasPermission('质控中心')">
          <template #title>
            <el-icon>
              <img src="@/assets/Vector@2x.png" width="15" height="15" />
            </el-icon>
            <span v-if="!isCollapse">质控中心</span>
          </template>
          <el-sub-menu index="2-1">
            <template #title>质控管理</template>
            <el-menu-item index="2-1-1" @click="gotoZScore">Z分数图</el-menu-item>
            <el-menu-item index="2-1-2" @click="gotoProductChart">L-J质控图</el-menu-item>
            <el-menu-item index="2-1-3" @click="gotoQualityProduct">质控品管理</el-menu-item>
            <el-menu-item index="2-1-4" @click="gotoQualityChannel">质控通道管理</el-menu-item>
            <!-- <el-menu-item index="2-1-5" @click="">质控规则设置</el-menu-item> -->
            <el-menu-item index="2-1-6" @click="gotoQualityRange">质控范围设置</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="2-2">
            <template #title>仪器性能验证</template>
            <el-menu-item index="2-2-1" @click="">性能验证分析</el-menu-item>
            <el-menu-item index="2-2-2" @click="gotoRangeSetting">可接受区间设置</el-menu-item>
            <el-menu-item index="2-2-3" @click="">验证参数设置</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="2-3">
            <template #title>靶机对比</template>
            <el-menu-item index="2-3-1" @click="">比对方案</el-menu-item>
            <el-menu-item index="2-3-2" @click="">比对计划</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="2-4">
            <template #title>手工录入</template>
            <el-menu-item index="2-4-1" @click="">单次录入</el-menu-item>
            <el-menu-item index="2-4-2" @click="">批量导入</el-menu-item>
          </el-sub-menu>
        </el-sub-menu>
        <el-sub-menu index="3" v-if="hasPermission('数据中心')">
          <template #title>
            <el-icon><img src="@/assets/Union@2x.png" width="15" height="15" /></el-icon>
            <span v-if="!isCollapse">数据中心</span>
          </template>
          <el-menu-item index="3-1" @click="">质控数据统计</el-menu-item>
          <el-menu-item index="3-2" @click="gotoSampleMonitor">样本检测量统计</el-menu-item>
          <el-menu-item index="3-3" @click="gotoReplacementReport">耗材更换记录</el-menu-item>
          <el-menu-item index="3-4" @click="">异常值统计</el-menu-item>
          <el-menu-item index="3-5" @click="gotoDeviceData">设备质控概览</el-menu-item>
          <el-menu-item index="3-6" @click="">测量不确定度</el-menu-item>
          <el-menu-item index="3-7" @click="">质控液不确定度</el-menu-item>
          <el-menu-item index="3-8" @click="gotoClinicReview">临床调阅</el-menu-item>
        </el-sub-menu>
        <!-- <el-sub-menu index="5" @click="gotoDocumentManager">
          <template #title>
            <el-icon>
              <img src="@/assets/file.png" width="15" height="15" />
            </el-icon>
            <span v-if="!isCollapse">文档管理</span>
          </template>
        </el-sub-menu> -->
        <el-menu-item index="4"  @click="gotoDocumentManager" v-if="hasPermission('文档管理')">
          <template #title>
            <el-icon>
              <img src="@/assets/file.png" width="15" height="15" />
            </el-icon>
            <span v-if="!isCollapse">文档管理</span>
          </template>
        </el-menu-item>
        <el-sub-menu index="5" v-if="hasPermission('系统设置')">
          <template #title>
            <el-icon>
              <img src="@/assets/setting2.png" width="15" height="15" />
            </el-icon>
            <span v-if="!isCollapse">系统设置</span>
            <span v-else>设置</span>
          </template>
          <el-menu-item index="5-1" @click="gotoSetting" v-if="hasPermission('系统设置')">系统设置</el-menu-item>
          <el-menu-item index="5-2" @click="gotoUserM" v-if="hasPermission('用户管理')">用户管理</el-menu-item>
          <el-menu-item index="5-3" @click="gotoRoleM" v-if="hasPermission('角色管理')">角色管理</el-menu-item>
          <el-menu-item index="5-4" @click="gotoDeviceM" v-if="hasPermission('设备管理')">设备管理</el-menu-item>
          <el-menu-item index="5-5" @click="gotoParaM" v-if="hasPermission('参数管理')">参数管理</el-menu-item>
          <el-menu-item index="5-6" @click="gotoTempM" v-if="hasPermission('打印模板')">打印模板</el-menu-item>
          <el-menu-item index="5-7" @click="gotoDepartM" v-if="hasPermission('科室管理')">科室管理</el-menu-item>
          <el-menu-item index="5-8" @click="gotoDicM" v-if="hasPermission('字典管理')">字典管理</el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-scrollbar>
    <div :class="['sidebar-footer', { 'sidebar-footer-collapsed': isCollapse }]">
      <div class="user-info" :class="{ 'user-info-collapsed': isCollapse }" @click="toggleUserMenu">
        <el-avatar class="user-avatar" />
        <span class="username">{{ username }}</span>
        <el-icon v-if="!isCollapse" class="arrow-icon" color="white">
          <ArrowRight />
        </el-icon>
      </div>
      <transition name="fade">
        <div v-if="showUserMenu" :class="['user-menu', { 'user-menu-collapsed': isCollapse }]">
          <div class="user-menu-header">
            <span class="user-menu-title">{{ username }}</span>
          </div>
          <el-menu class="user-menu-content">
            <el-menu-item @click="handleAccountSettings">
              <span class="menu-item-icon">
                <Setting />
              </span>
              <span class="menu-item-text">账号设置</span>
            </el-menu-item>
            <el-menu-item @click="handleChangePassword">
              <span class="menu-item-icon">
                <Unlock />
              </span>
              <span class="menu-item-text">修改密码</span>
            </el-menu-item>
            <el-menu-item @click="handleLogout" class="menu-item-logout">
              <span class="menu-item-icon" style="color: red;">
                <SwitchButton />
              </span>
              <span class="menu-item-text" style="color: red;">退出账号</span>
            </el-menu-item>
          </el-menu>
        </div>
      </transition>
    </div>
  </el-aside>
</template>

<script>
import { useAuthStore } from '@/stores/authStore';
import { Document, Setting, ArrowRight, SwitchButton, Unlock, Fold, Expand } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
export default {
  name: 'Sidebar',
  components: {
    Document,
    Setting,
    ArrowRight,
    SwitchButton,
    Unlock,
    Fold,
    Expand
  },
  data() {
    return {
      isCollapse: false, // 默认为展开状态
      username: 'admin',
      showUserMenu: false,
      permissions: { menus: []},
      systemLogoBase64: ''
    };
  },
  setup() {
    const router = useRouter();
    const gotoSetting = () => {
      router.push({ name: 'Setting' });
    };
    const gotoUserM = () => {
      router.push({ name: 'UserManagement' });
    };
    const gotoRoleM = () => {
      router.push({ name: 'RoleManagement' });
    };
    const gotoDeviceM = () => {
      router.push({ name: 'DeviceManagement' });
    };
    const gotoParaM = () => {
      router.push({ name: 'ParameterManagement' });
    };
    const gotoTempM = () => {
      router.push({ name: 'TemplateManagement' });
    };
    const gotoDepartM = () => {
      router.push({ name: 'DepartmentManagement' });
    };
    const gotoDicM = () => {
      router.push({ name: 'DictionaryManagement' });
    };
    const gotoRegM = () => {
      router.push({ name: 'RegistrationManager' });
    };
    const gotoReportM = () => {
      router.push({ name: 'ReportManager' });
    };
    const gotoQueryM = () => {
      router.push({ name: 'QueryManager' });
    };
    const gotoCri = () => {
      router.push({ name: 'CriticalManager' });
    };
    const gotoReportS = () => {
      router.push({ name: 'ReportSetting' });
    };
    const gotoDocumentManager = () => {
      router.push({ name: 'DocumentManager' });
    };
    const gotoZScore = () => {
      router.push({ name: 'ZScore' });
    };
    const gotoQualityProduct = () => {
      router.push({ name: 'QualityProduct' });
    };
    const gotoQualityChannel = () => {
      router.push({ name: 'QualityChannel' });
    };
    const gotoQualityRange = () => {
      router.push({ name: 'QualityRange' });
    };
    const gotoSampleMonitor = () => {
      router.push({ name: 'SampleMonitor' });
    };
    const gotoReplacementReport = () => {
      router.push({ name: 'ReplacementReport' });
    };
    const gotoDeviceData = () => {
      router.push({ name: 'DeviceData' });
    };
    const gotoProductChart = () => {
      router.push({ name: 'ProductChart' });
    };
    const gotoClinicReview = () => {
      router.push({ name: 'ClinicReview' });
    };
    const gotoRangeSetting = () => {
      router.push({ name: 'RangeSetting' });
    };
    return {
      gotoSetting,
      gotoUserM,
      gotoRoleM,
      gotoDeviceM,
      gotoParaM,
      gotoTempM,
      gotoDepartM,
      gotoDicM,
      gotoRegM,
      gotoReportM,
      gotoQueryM,
      gotoCri,
      gotoReportS,
      gotoDocumentManager,
      gotoZScore,
      gotoQualityProduct,
      gotoQualityChannel,
      gotoQualityRange,
      gotoSampleMonitor,
      gotoReplacementReport,
      gotoDeviceData,
      gotoProductChart,
      gotoClinicReview,
      gotoRangeSetting,
    };
  },
  computed: {
    toggleIcon() {
      return this.isCollapse ? Expand : Fold;
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log('open:', key, keyPath); // 打开菜单时的回调
    },
    handleClose(key, keyPath) {
      console.log('close:', key, keyPath); // 关闭菜单时的回调
    },
    toggleSidebar() {
      this.isCollapse = !this.isCollapse;
      this.$emit('toggleCollapse', this.isCollapse); // 发出事件通知父组件折叠状态变化
    },
    async fetchUserData() {
      const authStore = useAuthStore();
      this.username = authStore.user;
      this.username = authStore.user;
      const userData = await authStore.getCurrentUser();
      //console.log("userData:",userData);
      this.permissions = JSON.parse(userData.roles[0].permission);
      console.log("this.permissions:",this.permissions.menus);
    },
    async fetchSettings() {
      const authStore = useAuthStore();
      try {
        const configList = await authStore.fetchConfigList();
        configList.forEach(config => {
          if (config.configName === 'SYSTEM_LOGO_BASE64') {
            this.systemLogoBase64 = config.configValue;
          }
        });
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    },
    handleLogout() {
      const authStore = useAuthStore();
      authStore.clearToken();
      this.$router.push('/login');
    },
    handleAccountSettings() {
      // Handle account settings logic
    },
    handleChangePassword() {
      // Handle change password logic
    },
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    hasPermission(menuName) {
      if(!this.permissions||!this.permissions.menus){
        return false;
      }
      const checkPermission = (menus, name) => {
        for (const menu of menus) {
          if (menu.name === name && menu.hasPermission) {
            return true;
          }
          if (menu.children) {
            if (checkPermission(menu.children, name)) {
              return true;
            }
          }
        }
        return false;
      };
      return checkPermission(this.permissions.menus, menuName);
    }
  },
  mounted() {
    this.fetchUserData();
    this.fetchSettings();
    if (this.$route.name === 'Home') {
      this.gotoRegM();
    }
  }
};
</script>

<style scoped>
.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;
  background-color: #474747;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.el-menu-vertical-demo {
  flex: 1;
  border-right: none;
  font-size: 14px;
}

.el-menu-expanded {
  background-color: #474747;
  color: #C2C3C4;
  margin-left: 20px;
  margin-right: 20px;
}

.el-menu-collapsed {
  background-color: transparent !important;
  color: transparent !important;
}

:deep(.el-menu .el-menu-item:hover) {
  color: white;
}

:deep(.el-menu .el-menu-item.is-active) {
  background-color: #057AB2;
  border-radius: 4px;
}

.sidebar-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  margin-left: 12px;
  margin-right: 12px;
  background-color: #474747;
  color: white;
}

.sidebar-title {
  font-size: 20px;
}

.el-icon-menu {
  font-size: 20px;
}

.toggle-button {
  background-color: #474747;
  padding: 0;
  border: none;
}

.toggle-button .el-icon {
  width: 20px;
  height: 20px;
}

.toggle-button:hover {
  background-color: #474747;
}

.sidebar-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  background-color: #474747;
  transition: width 0.3s;
  z-index: 1010;
}

.sidebar-footer-collapsed {
  width: 68px;
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.user-info-collapsed {
  flex-direction: column;
  justify-content: center;
}

.user-info-collapsed .username {
  margin: 0px;
}

.username {
  color: white;
  cursor: pointer;
  margin-left: 7.15px;
}

.user-avatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.arrow-icon {
  margin-left: 60px;
  color: white;
}

.user-menu {
  background: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: fixed;
  bottom: 20px;
  left: calc(200px + 1px);
  z-index: 1000;
  text-align: left;
  width: 240px;
  height: 182px;
  padding: 10px 0px;
  transition: left 0.3s;
  overflow: hidden;
}

.user-menu .el-menu {
  border-right: 0px;
}

.user-menu-collapsed {
  left: calc(68px + 1px);
}

.user-menu-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
  margin-left: 20px;
}

.user-menu-content .el-menu-item {
  width: 240px;
  height: 52px;
  line-height: 52px;
  padding: 0 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.user-menu-content .menu-item-icon {
  margin-right: 10px;
}

.menu-item-icon {
  line-height: 20px;
  width: 20px;
  height: 20px;
}

.user-menu .el-menu-item span,
.user-menu .el-sub-menu span {
  color: #474747;
}

.user-menu-content .menu-item-logout {
  color: red;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.separator {
  width: 164px;
  height: 1px;
  background-color: #C2C3C4;
  margin: 8px auto;
}

.separator1 {
  width: 28px;
  height: 1px;
  background-color: #C2C3C4;
  margin: 8px auto;
}

/* 调整子菜单的缩进和间距 */
:deep(.el-menu--collapse .el-sub-menu__title) {
  padding-left: 20px !important;
  /* 控制一级菜单与二级菜单的距离 */
}

:deep(.el-menu--collapse .el-menu-item) {
  padding-left: 20px !important;
  /* 控制二级菜单与三级菜单的距离 */
}
.sidebar-logo{
  width: 100px;
  height: 40px;
}
</style>
