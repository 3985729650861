<template>
  <div class="template-management-container">
    <div class="template-management-box">
      <div class="header">
        <span class="title">打印模板</span>
        <el-input v-model="search" placeholder="请输入内容" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="showDialog = true" class="upload-button">上传模板</el-button>
      </div>
      <el-scrollbar class="table-container">
        <el-table :data="filteredTemplates" class="custom-table" :header-cell-style="{ background: '#F5F6F7' }">
          <el-table-column prop="name" label="模板名称" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="type" label="模板类型" width="auto" min-width="15%"></el-table-column>
          <el-table-column label="分组模板" width="auto" min-width="25%">
            <template v-slot="scope">
              {{ scope.row.groupType === 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column label="状态" width="auto" min-width="15%">
            <template v-slot="scope">
              {{ scope.row.status === 1 ? '启用' : '未启用' }}
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" width="auto" min-width="20%"></el-table-column>
          <el-table-column label="操作" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <div class="icon-group">
                <el-button class="action1" @click="editTemplate(scope.row)">
                  <el-icon size="16">
                    <Edit />
                  </el-icon>
                </el-button>
                <el-button class="action1"><el-icon size="16" @click="deleteTemplate(scope.row)">
                    <Delete />
                  </el-icon>
                </el-button>
                <el-button class="action1"><el-icon size="16" @click="viewTemplate(scope.row)">
                    <View />
                  </el-icon>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
      <el-dialog title="患者字段设置" v-model="showDialog" width="360px" style="border-radius:8px;">
        <el-form>
          <el-form-item label="模板名称">
            <el-input v-model="form.templateName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="模板类型">
            <el-select v-model="form.templateType" placeholder="请选择">
              <el-option label="A4" value="A4"></el-option>
              <el-option label="A5" value="A5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分组模板">
            <el-radio-group v-model="form.groupTemplate">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="模板状态">
            <el-radio-group v-model="form.templateStatus">
              <el-radio label="启用"></el-radio>
              <el-radio label="禁用"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showDialog = false" style="width:88px;height: 32px;">关闭</el-button>
          <el-button type="primary" class="ctrl" @click="saveTemplate">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Search, Edit, Delete, View } from '@element-plus/icons-vue';
import { useAuthStore } from '@/stores/authStore';
export default {
  components: {
    Search,
    Edit,
    Delete,
    View
  },
  data() {
    return {
      search: '',
      showDialog: false,
      form: {
        templateName: '',
        templateType: 'A4', // 默认选择A4
        groupTemplate: '否',
        templateStatus: '启用'
      },
      templates: [],
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    filteredTemplates() {
      if (!this.search) return this.templates;
      const lowerSearch = this.search.toLowerCase();
      return this.templates.filter(template =>
        Object.values(template).some(value =>
          String(value).toLowerCase().includes(lowerSearch)
        )
      );
    },
  },
  methods: {
    async fetchTemplates() {
      const authStore = useAuthStore();
      const params = { pageNumber: 1, pageSize: 10000 };
      try {
        const response = await authStore.getTemplateList(params);
        console.log(response);
        this.templates = response.data.data;
        console.log(this.templates)
      } catch (error) {
        console.error('Failed to fetch templates:', error);
      }
    },
    uploadTemplate() {
      console.log('上传模板');
      // 上传模板的逻辑
    },
    editTemplate(template) {
      console.log('编辑模板', template);
      // 编辑模板的逻辑
      this.currentTemplateId = template.id;
      this.form = {
        templateName: template.name,
        templateType: template.type,
        groupTemplate: template.groupType === 1 ? '是' : '否',
        templateStatus: template.status === 1 ? '启用' : '未启用',
      };
      this.showDialog = true;
    },
    async deleteTemplate(template) {
      //删除模板
      const authStore = useAuthStore();
      try {
        const response = await authStore.deleteTemplate(template.id);
        if (response.code === 200) {
          // Remove the deleted template from the local templates array
          this.templates = this.templates.filter(t => t.id !== template.id);
          this.$message.success('模板删除成功');
        } else {
          this.$message.error('模板删除失败');
        }
      } catch (error) {
        console.error('Failed to delete template:', error);
        this.$message.error('模板删除失败');
      }
    },
    viewTemplate(template) {
      console.log('查看模板', template);
      // 查看模板的逻辑
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    async saveTemplate() {
      const authStore = useAuthStore();
      const templateData = {
            name: this.form.templateName,
            type: this.form.templateType,
            groupType: this.form.groupTemplate === '是' ? 1 : 0,
            status: this.form.templateStatus === '启用' ? 1 : 0,
      };
      try {
        const response = await authStore.updateTemplate(this.currentTemplateId, templateData);
        if (response.code === 200) {
          // Update the local templates array with the updated template
          const index = this.templates.findIndex(template => template.id === this.currentTemplateId);
          if (index !== -1) {
            this.templates[index] = { ...this.templates[index], ...templateData };
          }
          this.showDialog = false;
          this.$message.success('模板更新成功');
        } else {
          this.$message.error('模板更新失败');
        }
      } catch (error) {
        console.error('Failed to update template:', error);
        this.$message.error('模板更新失败');
      }
    },
  },
  async mounted() {
    await this.fetchTemplates();
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  /* 确保 padding 不会增加元素的总高度 */
}

.template-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 居左布局 */
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.template-management-box {
  width: calc(100% - 20px);
  /* 减去左右padding */
  height: calc(100vh - 40px);
  /* 减去上下padding */
  background-color: #F0F3F5;
  border-radius: 12px;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  /* 距离顶部20px */
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: #057AB2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.upload-button {
  margin-left: 20px;
  background-color: #057AB2;
  /* 上传模板按钮的背景色 */
  border-color: #057AB2;
}

.upload-button:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.icon-group {
  display: flex;
  gap: 2px;
}

.icon-group .action1 {
  border: none;
  color: #057ab2;
  background-color: transparent;
}

.icon-group .action1:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
  border: none;
}

:deep(.icon-group .el-button+.el-button) {
  margin-left: 0px;
}

.el-table th,
.el-table td {
  text-align: center;
  /* 表头和单元格内容居中对齐 */
}

.el-pagination .el-pager .el-pagination__pager li.active {
  background-color: #057AB2;
  /* 分页选中当前页背景色 */
  border-color: #057AB2;
  color: #fff;
}

.table-container {
  /* flex: 1; */
  overflow: auto;
  position: relative;
  height: calc(100vh - 125px);
}

.adddialog {
  border-radius: 8px;
}

.ctrl {
  width: 88px;
  height: 32px;
  background-color: #057AB2;
}

.ctrl:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.pagination-container {
  height: 52px;
  /* 与表格底部预留空间匹配 */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
}
</style>