<template>
  <div class="registration-page">
    <div class="header">
      <div class="filter-bar">
        <!-- 过滤项 -->
        <div class="filter-item">
          <label for="dateStart">检验日期</label>
          <el-date-picker v-model="dateStart" type="date" placeholder="申请日期" class="filter-date" />-
          <el-date-picker v-model="dateEnd" type="date" placeholder="截止日期" class="filter-date" />
        </div>
        <div class="filter-item">
          <label for="hospital">医院</label>
          <el-select v-model="hospital" placeholder="选择医院" class="filter-input" @change="fetchDepartments">
            <el-option v-for="hospital in hospitalList" :key="hospital.hospitalCode" :label="hospital.hospitalName"
              :value="hospital.hospitalCode">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="department">科室</label>
          <el-select v-model="department" placeholder="选择科室" class="filter-input" @change="filterDevicesByDepartment">
            <el-option v-for="department in departmentList" :key="department.code" :label="department.name"
              :value="department.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="device">设备</label>
          <el-select v-model="device" placeholder="选择设备" class="filter-input">
            <el-option v-for="device in filteredDevices" :key="device.id" :label="device.name" :value="device.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <label for="patient">患者</label>
          <el-input v-model="patient" placeholder="姓名关键字" class="filter-input">
          </el-input>
        </div>
        <div class="filter-item">
          <label for="status">报告状态</label>
          <el-select v-model="status" placeholder="选择状态" class="filter-input">
            <el-option label="待绑定" value="await"></el-option>
            <el-option label="已保存" value="saved"></el-option>
            <el-option label="已审核" value="audited"></el-option>
            <el-option label="已发布" value="publish"></el-option>
            <el-option label="已作废" value="invalid"></el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <el-checkbox label="已打印"></el-checkbox>
        </div>
      </div>
      <div class="button-group">
        <el-button type="primary" @click="search" class="ctrl-button">查询</el-button>
        <el-button @click="reset" class="ctrl-button">重置</el-button>
      </div>
    </div>

    <div class="content">
      <div class="report-title">报告信息</div>
      <div class="panels" ref="panels">
        <div class="left-pane" ref="leftPane">
          <el-table :data="samples" height="100%" width="100%" @row-click="handleRowClick">
            <el-table-column type="selection" width="30" align="center"></el-table-column>
            <el-table-column prop="analyzeDate" label="检验时间" width="auto" min-width="160" :formatter="formatDateTime"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="sampleNum" label="样本号" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="applyStatus" label="状态" width="auto" min-width="70" show-overflow-tooltip
              :formatter="formatStatus"></el-table-column>
            <el-table-column prop="patientId" label="患者ID" width="auto" min-width="100"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.name" label="患者姓名" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.sourceCode" label="患者来源" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.inpatNum" label="住院号" width="auto" min-width="100"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="accessionNum" label="条码号" width="auto" min-width="110"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.cardNum" label="卡号" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.bedNum" label="床号" width="auto" min-width="80"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.sex" label="性别" width="auto" min-width="60"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.birthdayDate" label="出生日期" :formatter="formatDate" show-overflow-tooltip
              width="auto" min-width="100"></el-table-column>
            <el-table-column prop="patient.diagnosis" label="诊断" width="auto" min-width="60"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.sampleDesc" label="患者描述" width="auto" min-width="150"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.address" label="联系地址" width="auto" min-width="150"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="patient.phone" label="联系方式" width="auto" min-width="150"
              show-overflow-tooltip></el-table-column>
          </el-table>
          <div class="pagination-container">
            <div class="pagination">
              <el-pagination layout="prev, pager, next" :total="totalRecords" background :page-size="pageSize"
                :current-page="currentPage" @current-change="handlePageChange"></el-pagination>
            </div>
            <div class="total-records">共 {{ totalRecords }} 条</div>
          </div>
        </div>
        <div class="divider" ref="divider"></div>
        <div class="right-pane" ref="rightPane">
          <div class="table-container">
            <el-table :data="selectedRecords" ref="rightTable" height="100%" width="100%">
              <!-- 右侧表格列 -->
              <el-table-column type="index" label="序号" width="auto" min-width="60"></el-table-column>
              <el-table-column prop="code" label="参数" width="auto" min-width="100"
                show-overflow-tooltip></el-table-column>
              <el-table-column prop="name" label="项目名称" width="auto" min-width="140"
                show-overflow-tooltip></el-table-column>
              <el-table-column prop="value" label="结果" width="auto" min-width="100"></el-table-column>
              <el-table-column label="提示" width="auto" min-width="100">
                <template v-slot="{ row }">
                  <span
                    v-if="row.refRangeMin !== null && row.refRangeMax !== null && Number(row.value) < Number(row.refRangeMin)"
                    style="color: blue;">↓</span>
                  <span
                    v-else-if="row.refRangeMin !== null && row.refRangeMax !== null && Number(row.value) > Number(row.refRangeMax)"
                    style="color: red;">↑</span>
                </template>
              </el-table-column>
              <el-table-column prop="unit" label="单位" width="auto" min-width="80"></el-table-column>
              <el-table-column prop="referenceRange" label="参考范围" width="auto" min-width="120"><template
                  v-slot="{ row }">
                  <span v-if="row.refRangeMin !== null && row.refRangeMax !== null">
                    {{ row.refRangeMin }} - {{ row.refRangeMax }}
                  </span>
                  <span v-else>
                    —
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="hasReportInfo" class="report-action">
            <div class="report-info">
              <div v-if="selectedSample.applyStatus" class="info-item">
                <span class="info-label"><img src="@/assets/feiji.png" width="13px" /></span><span
                  class="info-label">报告状态：</span><span class="status-published">{{ mapStatus(selectedSample.applyStatus)
                  }}</span>
              </div>
              <div class="info-item">
                <span class="info-label"><img src="@/assets/dayin.png" width="13px" /></span><span
                  class="info-label">打印状态：</span>
                <span class="status-not-printed">{{ selectedSample.printCount === '' ? '未打印' : '已打印' }}</span>
              </div>
              <div v-if="selectedRecords[0]?.reviewer" class="info-item">
                <span class="info-label"><img src="@/assets/yinzhang.png" width="13px" /></span><span
                  class="info-label">审核人：</span>{{ selectedRecords[0]?.reviewer }}
              </div>
              <div v-if="selectedRecords[0]?.reviewTime" class="info-item">
                <span class="info-label"><img src="@/assets/shijian.png" width="13px" /></span><span
                  class="info-label">审核时间：</span>{{ selectedRecords[0]?.reviewTime }}
              </div>
            </div>
            <div class="action-buttons">
              <!-- <el-button type="danger" @click="revokeBarcode" class="barcode-button">
                回收
              </el-button>
              <el-button type="primary" @click="generateBarcode" class="barcode-button">
                打印
              </el-button> -->
              <template
                v-if="selectedSample.applyStatus === '' || selectedSample.applyStatus === 'await' || selectedSample.applyStatus === null">
                <el-button type="primary" @click="handleSave" class="ctrl-button small-font">保存</el-button>
              </template>
              <template v-else-if="selectedSample.applyStatus === 'saved'">
                <el-button type="danger" @click="handleInvalidate" class="ctrl-button small-font">作废</el-button>
                <el-button type="success" @click="handleAudit" class="ctrl-button small-font">审核</el-button>
              </template>
              <template v-else-if="selectedSample.applyStatus === 'invalid'">
                <el-button type="success" @click="handleRestore" class="ctrl-button small-font">恢复</el-button>
              </template>
              <template v-else-if="selectedSample.applyStatus === 'audited'">
                <el-button type="danger" @click="handleCancelAudit" class="ctrl-button small-font">取消审核</el-button>
                <el-button type="success" @click="handlePublish" class="ctrl-button small-font">发布</el-button>
              </template>
              <template v-else>
                <el-button type="primary" @click="handleRecycle" class="ctrl-button small-font">回收</el-button>
                <el-button type="success" @click="handlePrint" class="ctrl-button small-font">打印</el-button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useAuthStore } from '@/stores/authStore';
import { autocompleteProps, ElLoading, ElMessage } from 'element-plus';
import dayjs from 'dayjs';
export default {
  components: {
  },
  data() {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    return {
      patient: '',
      device: '',
      hospital: '',
      department: '',
      dateStart: thirtyDaysAgo,
      dateEnd: today,
      status: '',
      currentPage: 1,
      totalRecords: 0,
      pageSize: 20,
      selectedRecords: [],
      selectedSample: '',
      selectedRows: [],
      samples: [],
      allRecord: [],
      hospitalList: [],
      departmentList: [],
      deviceList: [], // 全部设备列表
      filteredDevices: [], // 过滤后的设备列表
      patientList: [],  // 存储患者列表
      patientLoading: false,  // 控制患者列表加载状态
    };
  },
  computed: {
    hasReportInfo() {
      return (
        this.selectedRecords.length > 0 &&
        (this.selectedRecords[0].status ||
          this.selectedRecords[0].printStatus ||
          this.selectedRecords[0].reviewer ||
          this.selectedRecords[0].reviewTime)
      );
    },
  },
  methods: {
    formatDate(row, column, value, index) {
      return dayjs(value).format('YYYY-MM-DD');  // 格式化日期
    },
    formatDateTime(row, column, value, index) {
      // 返回年月日时分秒
      return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
    },
    formatReferenceRange(row, column, cellValue, index) {
      return `${row.refRangeMin} - ${row.refRangeMax}`;
    },

    async handlePageChange(page) {
      this.currentPage = page;
      await this.search();
    },
    async fetchHospitals() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.hospitallist();
        if (response.code === 200) {
          this.hospitalList = response.data;
          if (this.hospitalList.length > 0) {
            this.hospital = this.hospitalList[0].hospitalCode;
            await this.fetchDepartments(this.hospital);
          }
        }
      } catch (error) {
        console.error('Error fetching hospitals:', error);
      }
    },
    async fetchDepartments(hospitalCode) {
      try {
        const authStore = useAuthStore();
        const response = await authStore.getDepartmentList(hospitalCode);
        if (response.code === 200) {
          this.departmentList = response.data;
          if (this.departmentList.length > 0) {
            this.department = this.departmentList[0].id; // 自动选择第一个科室
            await this.filterDevicesByDepartment(); // 自动获取该科室下的设备
          }
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },
    async fetchDevices() { // 获取设备列表
      try {
        const authStore = useAuthStore();
        const response = await authStore.devicelist();
        if (response.code === 200) {
          this.deviceList = response.data;
          await this.filterDevicesByDepartment(); // 初次加载时过滤设备
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    },
    filterDevicesByDepartment() { // 根据选中的科室过滤设备
      if (this.department) {
        this.filteredDevices = this.deviceList.filter(device => device.deptId === this.department);
        if (this.filteredDevices.length > 0) {
          this.device = this.filteredDevices[0].id; // 默认选择第一个设备
        }
      } else {
        this.filteredDevices = [];
        this.device = ''; // 如果没有科室选中，则清空设备选择
      }
    },
    reset() {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
      this.hospital = '';
      this.department = '';
      this.dateStart = thirtyDaysAgo;
      this.dateEnd = today;
      this.status = '';
      this.device='';
      console.log('重置条件');
      // 实现重置逻辑
    },
    async search() {
      //const loading = ElLoading.service({ fullscreen: true });
      this.samples = [];
      try {
        const authStore = useAuthStore();
        let params = {
          deviceId: this.device,
          startCheckTime: new Date(this.dateStart).getTime(),
          endCheckTime: new Date(this.dateEnd.setHours(23, 59, 59, 999)).getTime(),
          //startCheckTime: 1693497600,
          //endCheckTime: 1696089599,
          pageIndex: this.currentPage,
          pageSize: this.pageSize
        };
        // 只有当状态被明确选择时才添加到参数中
        if (this.status) {
          params.applyStatus = this.status;
        }
        if (this.patient) {
          params.patientName = this.patient;

        }

        const response = await authStore.sampleList(params);
        if (response.code === 200) {
          if (response.data.total === 0) {
            ElMessage({
              message: '没有找到匹配的记录，请调整搜索条件。',
              type: 'info'
            });
          } else {
            //检测样本
            this.samples = response.data.data.map((item) => ({
              id: item.id || '',
              sampleNum: item.sampleNum || '',
              accessionNum: item.accessionNum || '',
              deviceId: item.deviceId || '',
              deviceName: item.deviceName || '',
              requestDeptName: item.requestDeptName || '',
              patientId: item.patientId || '',
              creatorEmplId: item.creatorEmplId || '',
              creatorEmplName: item.creatorEmplName || '',
              payStatus: item.payStatus || '',
              auditedEmplId: item.auditedEmplId || '',
              auditedEmplName: item.auditedEmplName || '',
              operationEmplId: item.operationEmplId || '',
              operationEmplName: item.operationEmplName || '',
              printCount: item.printCount || '',
              applyStatus: item.applyStatus,
              analyzeDate: item.analyzeDate || '',
              auditedDate: item.auditedDate || '',
              updateDate: item.updateDate || '',
              createDate: item.createDate || '',
              deptId: item.deptId || '',
              listParams: item.listParams ? item.listParams.map(param => ({
                id: param.id || '',
                code: param.code || '',
                name: param.name || '',
                unit: param.unit || '',
                sort: param.sort || 0,
                status: param.status || 0,
                type: param.type || '',
                calcType: param.calcType || '',
                payType: param.payType || '',
                refRangeMin: param.refRangeMin || '',
                refRangeMax: param.refRangeMax || '',
                criticalRangeMin: param.criticalRangeMin || '',
                criticalRangeMax: param.criticalRangeMax || '',
                refRange: param.refRange || '',
                criticalRange: param.criticalRange || '',
                value: param.value || '',
                sampleId: param.sampleId || '',
                createTime: param.createTime ? new Date(param.createTime).toLocaleString() : '',
                deviceId: param.deviceId || '',
                dotNum: param.dotNum || 0,
                listSpecialParam: param.listSpecialParam || ''
              })) : [],
              patient: item.patient || '',
              isSaved: item.isSaved || '',
            }));
            this.totalRecords = response.data.total;
          }
        } else {
          ElMessage.error(response.msg || '获取数据失败');
        }
      } catch (error) {
        console.error('Error fetching samples:', error);
        ElMessage.error('搜索请求失败');
      } finally {
        //loading.close();
      }
    },
    async handleRowClick(row) {
      console.log('选中的行:', row);
      this.selectedSample = row;
      this.selectedRecords = []; // 清空之前的选中记录
      try {
        const authStore = useAuthStore();
        const response = await authStore.getSample(row.id); // 使用行的 id 来获取详细信息
        if (response && response.code === 200) {
          this.selectedRecords = response.data; // 假设详细信息在 data 字段中，设置到 selectedRecords
        } else {
          console.error('Failed to fetch sample details:', response.msg);
          this.$message.error('获取样本详细信息失败: ' + response.msg);
        }
      } catch (error) {
        console.error('Error fetching sample details:', error.message);
        this.$message.error('获取样本详细信息异常: ' + error.message);
      }
    },
    generateBarcode() {
      console.log('Generating barcode...');
      // Logic to generate barcode
    },
    revokeBarcode() {
      console.log('Revoking barcode...');
      // Logic to revoke barcode
    },
    initResizableColumns() {
      const leftPane = this.$refs.leftPane;
      const rightPane = this.$refs.rightPane;
      const divider = this.$refs.divider;

      if (!leftPane || !rightPane || !divider || !this.$refs.panels) {
        console.error('One or more elements are undefined, please check refs.');
        return;
      }

      let isDragging = false;

      const handleMouseMove = (e) => {
        if (!isDragging) return;
        const dimensions = this.$refs.panels.getBoundingClientRect();
        const leftWidth = e.clientX - dimensions.left;
        const rightWidth = dimensions.right - e.clientX;
        if (leftWidth > 300 && rightWidth > 0) {
          leftPane.style.width = `${leftWidth}px`;
          rightPane.style.width = `${rightWidth}px`;
        }
      };

      const stopDrag = () => {
        isDragging = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', stopDrag);
      };

      divider.addEventListener('mousedown', (e) => {
        isDragging = true;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', stopDrag);
      });
    },
    formatStatus(row, column, value, index) {
      return this.mapStatus(value);  // 调用 mapStatus 来转换状态码为中文
    },
    // 辅助方法：将状态码转换为中文
    mapStatus(status) {
      const statusMap = {
        'await': '待绑定',
        'saved': '已保存',
        'audited': '已审核',
        'publish': '已发布',
        'invalid': '已作废'
      };
      // 考虑到 null 或未定义状态，均视为 '待绑定'
      return status ? (statusMap[status] || '未知状态') : '待绑定';
    },
    // 搜索患者，用户输入时触发
    async searchPatient(query) {
      if (query !== '') {
        this.patientLoading = true;
        try {
          const authStore = useAuthStore();
          const params = { name: query };  // 将用户输入的关键词作为查询参数
          const response = await authStore.getPatient(params);  // 调用 getPatient 方法
          if (response && response.code === 200) {
            this.patientList = response.data;  // 将获取到的患者数据设置到 patientList 中
          } else {
            ElMessage.error('获取患者列表失败: ' + response.msg);
          }
        } catch (error) {
          console.error('Error fetching patients:', error.message);
          ElMessage.error('搜索患者失败: ' + error.message);
        } finally {
          this.patientLoading = false;
        }
      } else {
        this.patientList = [];  // 如果输入框为空，清空患者列表
      }
    },
  },
  async mounted() {
    await this.fetchHospitals();
    await this.fetchDevices(); // 获取设备列表
    this.initResizableColumns();
  },
};
</script>
<style scoped>
.registration-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  width: calc(100% - 20px);
  background-color: #474747;
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: 400;
}

.header {
  background-color: #474747;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.filter-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.filter-item {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

:deep(.filter-item .el-checkbox__label) {
  color: white;
  font-size: 16px;
}

.filter-input,
.filter-date {
  flex: 1;
  min-width: 150px;
}

.button-group {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.ctrl-button {
  width: 88px;
}

.registration-page .content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  border-radius: 12px;
  background-color: #f0f3f5;
  overflow: hidden;
}

.report-title {
  width: 100%;
  padding: 10px;
  color: #057AB2;
  font-weight: 500;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.panels {
  display: flex;
  margin: 0px;
  height: 94%;
  padding: 0px;
}

.left-pane {
  padding: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 50%;
  border-radius: 12px 0px 0px 12px;
}

.divider {
  background-color: #ccc;
  cursor: ew-resize;
  width: 5px;
  /* 分隔线的宽度 */
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  gap: 10px;
}

:deep(.left-pane .el-table .el-table__cell) {
  padding: 3px 0;
}

:deep(.right-pane .el-table .el-table__cell) {
  padding: 3px 0;
}

.right-pane {
  flex: 1;
  display: flex;
  background-color: white;
  overflow: hidden;
  border-radius: 0px 12px 12px 0px;
  position: relative;
  flex-direction: column;
  width: 10px;
  height: 100%;
}

.table-container {
  padding: 5px;
  height: calc(100% - 70px);
  box-sizing: border-box;
}

.report-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.report-info {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 10px;
  font-size: 14px;
  color: #666;
}

.info-item {
  display: flex;
  align-items: center;
}

.info-label {
  margin-right: 5px;
}

.status-published {
  color: green;
}

.status-not-printed {
  color: orange;
}

.action-buttons {
  display: flex;
  margin-right: 20px;
}

.barcode-button {
  width: 88px;
  margin: 0;
}
</style>
